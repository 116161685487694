import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="96vh"
          w="100%"
          image={`url(${mobileHero}) top / cover no-repeat`}
          column
        >
          <Header />
          <CFView textCenter column center mt="10%" w="100%">
            <CFView mv="10px">
              <CFText h3 raleway white>
                Welcome to Kojan Sushi
              </CFText>
            </CFView>
            <CFView w="95%" maxWidth="390px">
              <CFText style={{ fontSize: 45 }} raleway xBold white>
                Now Taking Online Orders
              </CFText>
            </CFView>
            <CFView mt="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="690px"
          w="100%"
          image={`url(${hero}) 20% 0% / cover no-repeat`}
          zIndex={90}
          column
        >
          <Header />
          <CFView column justifyCenter alignStart ml="6%" h="100%" mt="15px">
            <CFImage
              w="50%"
              maxWidth="600px"
              src={heroText}
              alt="Kojan Sushi hero text"
              zIndex={98}
            />
            <CFView mt="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
